// import Bootstrap
import 'bootstrap';

// import template styles
import './scss/main.scss'

import Lang from './js/components/lang/lang'
const lang = new Lang
lang.init()

import Scroll from './js/components/scroll/scroll'
const scroll = new Scroll
scroll.init()

import Catalog from "./js/catalog-new";
const catalog = new Catalog()
catalog.init()