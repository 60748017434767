// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import {logPlugin} from "@babel/preset-env/lib/debug";

class Catalog {
    /**
     * Init catalog app
     * */
    init() {
        // modern Chrome requires { passive: false } when adding event
        this.checkPassiveSupport()

        this.catalogListSlider
        if (window.matchMedia('(min-width: 992px)').matches) {
            this.catalogItemCollectionSlider()
        }

        this.catalogItemsAnchors()
        this.catalogItemsScroll()

        this.catalogSliderScroll()

        this.openCurrentCollection()

        this.openCollections()
        this.closeCollection()
        this.collectionsImgClick()

        window.addEventListener('scroll', () => {
            this.catalogItemsScroll()
            this.catalogSliderScroll()
        })
    }

    /**
     * Init slider for catalog items navigation
     * */
   /* catalogListSlider = new Swiper(
        '.catalog__list-swiper',
        {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 0,
            grabCursor: true,
            centeredSlides: false,
            breakpoints: {
                992: {
                    centeredSlidesBounds: true
                }
            }
        }
    )*/

    /**
     * Init slider for collections items
     * */
    catalogItemCollectionSlider() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner')
        catalogItems.forEach(item => {
            let itemId = item.getAttribute('id')

            let collectionSlider = new Swiper(
                '#' + itemId + ' .catalog__products',
                {
                    direction: 'vertical',
                    slidesPerView: 'auto',
                    freeMode: true,
                    mousewheel: true,
                    grabCursor: true,
                    scrollbar: {
                        el: '.catalog__products-scrollbar',
                        dragClass: 'catalog__products-scrollbar-drag'
                    }
                }
            )
        })
    }

    /**
     * Fix navigation slider while scrolling page
     * */
   /* catalogSliderScroll() {

        if (window.matchMedia('(min-width: 992px)').matches) {
            const catalogListContainer = document.querySelector('.catalog__list-container'),
                catalogList = document.querySelector('.catalog__list'),
                catalogListPositionTop = catalogListContainer.getBoundingClientRect().top

            if(catalogListPositionTop < -500) {
                catalogList.classList.add('fixed')
            }else{
                catalogList.classList.remove('fixed')
            }
        }
    }*/

    /**
     * Catalog items scroll behaviour
     * */
    catalogItemsScroll() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner'),
            catalogItemsContainer = document.querySelector('.catalog__item'),
            catalogItemsLinks = document.querySelectorAll('.catalog__list-item')
        catalogItems.forEach(item => {
            let itemPositionTop = item.getBoundingClientRect().top,
                itemPositionBottom = item.getBoundingClientRect().bottom,
                id = item.getAttribute('id'),
                type = item.getAttribute('data-type'),
                windowHalfHeight = window.innerHeight / 2

            /**
             * Check if item container is in visibility area
             * */
            if(itemPositionTop <= windowHalfHeight && itemPositionBottom > windowHalfHeight) {
                /**
                 * Change background color by adding additional class
                 * */
                if(type === 'secondary'){
                    catalogItemsContainer.classList.add('catalog__item--secondary')
                }else{
                    catalogItemsContainer.classList.remove('catalog__item--secondary')
                }
                /**
                 * Add active class to current catalog item in navigation slider
                 * */
                catalogItemsLinks.forEach((link, index) => {
                    let itemId = link.getAttribute('href').substr(1)
                    if(itemId === id){
                        link.classList.add('active')
                        /**
                         * Scroll navigation slider to slide with active catalog item
                         * */
                        this.catalogListSlider.slideTo(index)
                    }else{
                        link.classList.remove('active')
                    }
                })
            }
        })
    }

    /**
     * Click on catalog items anchors
     * */
    catalogItemsAnchors() {
        const catalogItems = document.querySelectorAll('.catalog__list-item')
        catalogItems.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault()

                let targetId = link.getAttribute('href').substr(1),
                    activeCollection = document.querySelector('.catalog__item-inner.selected'),
                    timeout = 0

                if (!!activeCollection) {
                    this.closeCurrentCollection(activeCollection)
                    timeout = 700
                }

                this.collectionScroll(targetId, timeout)
            })
        })
    }

    /**
     * Scroll to collection
     *
     * @param targetId - collection ID
     * @param timeout - scroll timeout
     * */
    collectionScroll(targetId, timeout) {
        let scrollTarget = document.getElementById(targetId),
            elementPosition = scrollTarget.getBoundingClientRect().top

        setTimeout(function () {
            window.scrollBy({
                top: elementPosition - 150,
                behavior: 'smooth'
            })
        }, timeout)
    }

    /**
     * Open collection by click on button
     * */
    openCollections() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner')

        catalogItems.forEach(catalogItem => {
            let collectionLink = catalogItem.querySelector('.catalog__item-link'),
                collectionImg = catalogItem.querySelectorAll('.catalog__item-img'),
                collectionID = catalogItem.getAttribute('id')

            collectionLink.addEventListener('click', (e) => {
                e.preventDefault()

                collectionImg.forEach(img => {
                    this.imgClassToggle(img)
                })
                this.openCollectionHandler(catalogItems, catalogItem, collectionID)
            })
        })
    }

    /**
     * Open/close collection by click on image
     * */
    collectionsImgClick() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner')

        catalogItems.forEach(catalogItem => {
            let collectionImg = catalogItem.querySelectorAll('.catalog__item-img'),
                collectionID = catalogItem.getAttribute('id')

            collectionImg.forEach(img => {
                img.addEventListener('click', () => {
                    if (!img.classList.contains('opened')) {
                        this.imgClassToggle(img)
                        this.openCollectionHandler(catalogItems, catalogItem, collectionID)
                    } else {
                        this.imgClassToggle(img, true)
                        this.closeCurrentCollection(catalogItem)
                    }
                })
            })
        })
    }

    imgClassToggle(img, opened = false) {
        if (opened) {
            img.classList.remove('opened')
        } else {
            console.log(123)
            img.classList.add('opened')
        }
    }

    /**
     * Open collection handler
     *
     * @param catalogItems - NodeList of collections
     * @param catalogItem - current collection Element
     * @param collectionID - current collection ID
     * */
    openCollectionHandler(catalogItems, catalogItem, collectionID) {
        catalogItems.forEach(item => {
            item.classList.remove('selected')
        })
        catalogItem.classList.add('selected')
        if (window.matchMedia('(max-width: 991px)').matches) {
            document.querySelector('body').classList.add('fix-over')
        }

        this.disableScroll()
        this.changeUrlPath(collectionID)
        this.collectionScroll(collectionID, 0)
    }

    /**
     * Close collection on click
     * */
    closeCollection() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner')

        catalogItems.forEach(catalogItem => {
            let collectionBackLink = catalogItem.querySelector('.catalog__products-back'),
                collectionCloseLink = catalogItem.querySelector('.catalog__products-close'),
                collectionImg = catalogItem.querySelectorAll('.catalog__item-img')

            collectionBackLink.addEventListener('click', (e) => {
                e.preventDefault()
                collectionImg.forEach(img => {
                    this.imgClassToggle(img, true)
                })
                this.closeCurrentCollection(catalogItem)
            })
            collectionCloseLink.addEventListener('click', (e) => {
                e.preventDefault()
                collectionImg.forEach(img => {
                    this.imgClassToggle(img, true)
                })
                this.closeCurrentCollection(catalogItem)
            })
        })
    }

    /**
     * Close current collection
     *
     * @param catalogItem {Element} - catalog item
     * */
    closeCurrentCollection(catalogItem) {
        catalogItem.classList.remove('selected')
        if (window.matchMedia('(max-width: 991px)').matches) {
            document.querySelector('body').classList.remove('fix-over')
        }
        this.enableScroll()
        this.changeUrlPath()
    }

    /**
     * Open current collection
     * */
    openCurrentCollection() {
        const catalogItems = document.querySelectorAll('.catalog__item-inner')
        let urlParts = this.getUrlParts()

        if (!!urlParts[1]) {
            catalogItems.forEach(catalogItem => {
                let collectionID = catalogItem.getAttribute('id'),
                    _this = this

                if (collectionID === urlParts[1]) {
                    let scrollTarget = document.getElementById(collectionID),
                        elementPosition = scrollTarget.getBoundingClientRect().top

                    setTimeout(function () {
                        window.scrollBy({
                            top: elementPosition - 150,
                            behavior: 'smooth'
                        })
                    }, 300)

                    setTimeout(function () {
                        catalogItem.classList.add('selected')

                        if (window.matchMedia('(max-width: 991px)').matches) {
                            document.querySelector('body').classList.add('fix-over')
                        }

                        _this.disableScroll()
                        _this.changeUrlPath(collectionID)
                    }, 1000)
                }
            })
        }
    }

    keys = {37: 1, 38: 1, 39: 1, 40: 1}

    preventDefault(e) {
        e.preventDefault()
    }

    preventDefaultForScrollKeys(e) {
        if (this.keys[e.keyCode]) {
            this.preventDefault(e)
            return false
        }
    }

    supportsPassive = false
    wheelOpt = false
    wheelEvent = 'mousewheel'

    checkPassiveSupport() {
        let _this = this
        try {
            window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
                get: function () {
                    _this.supportsPassive = true
                }
            }));
        } catch(e) {}

        this.wheelOpt = this.supportsPassive ? { passive: false } : false
        this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    }

    disableScroll() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
            window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
            window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
            window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        }
    }

    enableScroll() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            window.removeEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
            window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
            window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
            window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        }
    }

    origin = window.location.origin

    changeUrlPath(collectionID = '') {
        let urlParts = this.getUrlParts(),
            path = urlParts[0] === 'ua' || urlParts[0] === 'de' || urlParts[0] === 'pl' ? urlParts[0] + '/' + urlParts[1] : urlParts[0],
            search = window.location.search,
            newPath = '/' + path + '/'

        if (collectionID !== '') {
            newPath += collectionID + '/'
        }

        history.pushState(null, null, this.origin + newPath + search)
    }

    getUrlParts() {
        let currentPath = window.location.pathname,
            parts = currentPath.split('/'),
            pathParts = []

        parts.forEach(part => {
            if (part !== '') {
                pathParts.push(part)
            }
        })

        return pathParts
    }
}

export default Catalog