import {logPlugin} from "@babel/preset-env/lib/debug";

class Scroll {
    refs = {
        scrollButton: document.querySelector('.scroll'),
        visible: false
    }

    init() {
        this.buttonVisibility()
        window.addEventListener('scroll', () => {
            this.buttonVisibility()
        })
        this.buttonClick()
    }

    buttonVisibility() {
        let clientHeight = document.documentElement.clientHeight

        if (window.pageYOffset > clientHeight) {
            if (!this.refs.visible) {
                this.refs.scrollButton.classList.add('visible')
            }
            this.refs.visible = true
        } else {
            if (this.refs.visible) {
                this.refs.scrollButton.classList.remove('visible')
            }
            this.refs.visible = false
        }
    }

    buttonClick() {
        this.refs.scrollButton.addEventListener('click', (e) => {
            e.preventDefault()

            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        })
    }
}

export default Scroll